




























































import { defineComponent, ComponentInstance, computed } from "@vue/composition-api";
import draggable from "vuedraggable";
import EadStatus from "@monorepo/inventory/src/views/EadView/components/EadStatus.vue";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCells from "@monorepo/uikit/src/components/tableViews/ToggleTableCells.vue";
import { mapActions, mapGetters } from "vuex";
import { IDigitalSignatures, IEadElement } from "@monorepo/inventory/src/views/EadView/types/eadElement";
import moment from "moment";
import { getFileSize } from "@monorepo/utils/src/utils/getFileSize";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";

interface IResultData {
  id: string | number;
  eadId: string;
  documentId: string;
  documentTitle: string;
  documentDate: string;
  documentRegistrationDate: string;
  storageDate: string;
  storageYear: string | number;
  status: string;
  tkId: string;
  digitalSignatures: IDigitalSignatures[];
  guid: string;
}

const tableHeaders = [
  {
    width: 80,
    defaultWidth: 80,
    value: "status",
    text: "Статус",
    tooltip: "Статус поступления документа в ЦХЭД может принимать значения: Получен, Не получен",
  },
  {
    width: 100,
    defaultWidth: 100,
    value: "index",
    text: "Порядковый номер",
    tooltip: "Порядковый номер документа в деле (список должен быть отсортирован по данному атрибуту)",
  },
  { width: 250, defaultWidth: 250, value: "title", text: "Заголовок", tooltip: "Заголовок документа", isLink: true },
  {
    width: 150,
    defaultWidth: 150,
    value: "authenticity",
    text: "Подлинность",
    tooltip: "Подлинность документа может принимать значения: Подлинник, Копия",
    isHybridOnly: true,
  },
  {
    width: 150,
    defaultWidth: 150,
    value: "storageKind",
    text: "Вид носителя",
    tooltip: "Вид носителя может принимать значения: Бумажный, Электронный",
    isHybridOnly: true,
  },
  { width: 250, defaultWidth: 250, value: "archiveCipher", text: "Архивный шифр", tooltip: "Архивный шифр документа" },
  { width: 250, defaultWidth: 250, value: "number", text: "Регистрационный номер", tooltip: "Регистрационный номер документа в СЭД" },
  { width: 116, defaultWidth: 160, value: "registrationDate", text: "Дата регистрации в СЭД", tooltip: "Дата регистрации документа в СЭД" },
  { width: 116, defaultWidth: 160, value: "totalSize", text: "Объём", tooltip: "Объём" },
];

export default defineComponent({
  name: "DocumentsTable",
  components: {
    ToggleTableCells,
    TableSettings,
    draggable,
    EadStatus,
    InfiniteScroll,
  },
  props: {
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isHybrid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableHeaders,
      data: [] as any[],
    };
  },
  computed: {
    ...mapGetters("inventoryView", { getCells: "documentsTableCells", documentList: "documentList" }),
    draggableCells: {
      get(this: { headers: typeof tableHeaders }): typeof tableHeaders {
        return this.headers;
      },
      set(value: typeof tableHeaders) {
        console.log("SET!", value);
        this.setDocumentsTableCells(this.checkCheckboxOnFirstPosition([...value]).map((item) => item.value));
      },
    },
    resultData(): Partial<IResultData>[] {
      return (
        this.data.map((ead: IEadElement, index: number) => {
          return {
            id: ead?.id,
            status: ead?.status,
            index: index + 1,
            title: ead?.title,
            archiveCipher: ead?.archiveCipher,
            number: ead?.number,
            registrationDate: moment(ead?.registrationDate).format("DD-MM-YYYY"),
            totalSize: getFileSize(+ead.totalSize),
            authenticity: ead?.authenticity?.title || "",
            storageKind: ead?.storageKind?.title || "",
          };
        }) || []
      );
    },
  },
  methods: {
    ...mapActions("inventoryView", ["setDocumentsTableCells", "getEadTreeData"]),
    async handleEadTreeData() {
      const { data, totalLength } = await this.getEadTreeData({
        length: this.data?.length || 0,
        id: this.id,
      });

      this.data = this.data.concat(data || []);
      return { data: this.data, totalLength };
    },
    checkCheckboxOnFirstPosition(value: typeof tableHeaders) {
      return value.sort((element) => (element.value === "checkbox" ? -1 : 0));
    },
    onScrollCb(e: Event) {
      const element = document.getElementById("doc-table");
      if (element) {
        element.scrollLeft = (e.target as HTMLElement)?.scrollLeft;
      }
    },
    openEad(title: string) {
      if (!title) {
        return;
      }
      this.$router.push({ path: "/inventory/ead", query: { title, isOpenModal: "1" } });
    },
  },
  watch: {
    id() {
      this.data = [];
    },
    isLoading() {
      this.data = [];
    },
  },
  setup(props: Record<string, unknown>, { root }: { root: ComponentInstance }) {
    const resultTableHeaders = computed(() => (props.isHybrid ? tableHeaders : tableHeaders.filter((item) => !item.isHybridOnly)) as unknown[]);
    const { headers } = useTableCells(root, "inventoryView", resultTableHeaders, 1120, false, false, { getCellsName: "documentsTableCells" });
    return {
      headers,
    };
  },
});
