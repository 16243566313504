














































import Vue from "vue";
import { defineComponent, PropType, computed } from "@vue/composition-api";
import draggable from "vuedraggable";
import { fields } from "@monorepo/utils/src/variables/projectsData/actsView/fields";
import ElectronicSignatureTableCell from "@monorepo/inventory/src/components/ElectronicSignatureTableCell.vue";
import EadStatus from "@monorepo/inventory/src/views/EadView/components/EadStatus.vue";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCells from "@monorepo/uikit/src/components/tableViews/ToggleTableCells.vue";
import moment from "moment";
import { IEadElement } from "../../EadView/types/eadElement";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";

interface IResultData {
  id: string | number;
  documentId: string;
  documentTitle: string;
  documentRegistrationDate: string;
  status: string;
}

type Keys = keyof typeof fields;
type Values = typeof fields[Keys];

const tableHeaders = [
  { defaultWidth: 170, value: fields.DOCUMENTS_NUMBER, text: "№ документа", tooltip: "Регистрационный номер документа в СЭД" },
  { defaultWidth: 260, value: fields.DOCUMENTS_TITLE, text: "Заголовок", tooltip: "Заголовок документа" },
  { defaultWidth: 256, value: fields.DOCUMENTS_ARCHIVAL_CIPHER, text: "Архивный шифр", tooltip: "Архивный шифр документа" },
  {
    defaultWidth: 256,
    value: fields.DOCUMENTS_ARCHIVAL_CIPHER_CASE,
    text: "Архивный шифр дела",
    tooltip: "Архивный шифр дела, с которым связан ЭАД",
  },
  {
    defaultWidth: 160,
    value: fields.DOCUMENTS_INDEX_CASE,
    text: "Индекс дела",
    tooltip: "Индекс дела, с которым связан ЭАД",
  },
  {
    defaultWidth: 236,
    value: fields.DOCUMENTS_TITLE_CASE,
    text: "Заголовок дела",
    tooltip: "Заголовок дела, с которым связан ЭАД",
  },
  {
    defaultWidth: 160,
    value: fields.DOCUMENTS_DATE_ARRIVAL,
    text: "Дата поступления",
    tooltip: "Дата поступления в ЦХЭД",
  },
  { defaultWidth: 100, value: fields.DOCUMENTS_CHIPBOARD, text: "ДСП", tooltip: "Признак документа для служебного пользования" },
];

export default defineComponent({
  name: "DocumentsTable",
  components: {
    ElectronicSignatureTableCell,
    ToggleTableCells,
    TableSettings,
    draggable,
    EadStatus,
  },
  props: {
    items: {
      type: Array as PropType<IEadElement[]>,
      required: true,
    },
  },
  data() {
    return {
      tableHeaders,
      selectedKey: "id",
      selectedIdsElements: {} as Record<string | number, boolean>,
      isSelectAll: false,
    };
  },
  computed: {
    // ...mapGetters("actsView", { getCells: "documentsTableCells" }),
    // draggableCells: {
    //   get(this: { headers: typeof tableHeaders }): typeof tableHeaders {
    //     return this.headers;
    //   },
    //   set(value: typeof tableHeaders) {
    //     this.setDocumentsTableCells(this.checkCheckboxOnFirstPosition([...value]).map((item) => item.value));
    //   },
    // },
    resultData(): Record<Values, unknown>[] {
      return [...this.items].map((item: IEadElement) => {
        return {
          [fields.DOCUMENTS_NUMBER]: item.number ?? "",
          [fields.DOCUMENTS_TITLE]: item.title ?? "",
          [fields.DOCUMENTS_ARCHIVAL_CIPHER]: item.archiveCipher ?? "",
          [fields.DOCUMENTS_ARCHIVAL_CIPHER_CASE]: item.caseDto?.archiveCipher ?? "",
          [fields.DOCUMENTS_INDEX_CASE]: item.caseDto.index ?? "",
          [fields.DOCUMENTS_TITLE_CASE]: item.caseDto.header ?? "",
          [fields.DOCUMENTS_DATE_ARRIVAL]: item.createDate ? moment(item.createDate).format("YYYY-MM-DD HH:mm") : "",
          [fields.DOCUMENTS_CHIPBOARD]: item.restricted ? "Да" : "Нет",
        };
      });
    },
  },
  methods: {
    // ...mapActions("inventoryView", ["setDocumentsTableCells"]),
    changeCells() {
      // this.setDocumentsTableCells(...args);

      Vue.nextTick(() => {
        const element = document.getElementById("doc-table-headers");
        if (element) {
          element.scrollLeft = 0;
        }
      });
    },
    clickCheckbox(id: string | number) {
      if (this.isSelectAll) {
        this.selectedIdsElements = {};
      }

      this.selectedIdsElements = {
        ...this.selectedIdsElements,
        [id]: !this.selectedIdsElements[id],
      };

      return null;
    },
    selectAll(value: IResultData[]) {
      this.selectedIdsElements = value.reduce((result: Record<string | number, boolean>, item) => {
        const key = item[this.selectedKey as keyof IResultData] as string;
        result[key] = true;
        return result;
      }, {});
    },
    clearAll() {
      this.selectedIdsElements = {};
    },
    clickElement(header: { value: string }, event: { id: string }) {
      if (header.value === "id" && event.id) {
        this.$router.push({ path: "/dictionaries/case", query: { number: event.id, isOpenModal: "1" } });
      }
    },
    checkCheckboxOnFirstPosition(value: typeof tableHeaders) {
      return value.sort((element) => (element.value === "checkbox" ? -1 : 0));
    },
    onScrollCb(e: Event) {
      const element = document.getElementById("doc-table");
      if (element) {
        element.scrollLeft = (e.target as HTMLElement)?.scrollLeft;
      }
    },
    openEad(id: string, isIdTk: boolean) {
      if (!id) {
        return;
      }
      this.$router.push({
        path: isIdTk ? "/inventory/tk" : "/inventory/ead",
        query: { id, isOpenModal: "1" },
      });
    },
  },
  setup(props, { root }) {
    const resultTableHeaders = computed(() => tableHeaders as unknown[]);
    const { headers } = useTableCells(root, "", resultTableHeaders);
    return {
      headers,
    };
  },
});
