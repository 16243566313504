




















import { defineComponent } from "@vue/composition-api";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";

export default defineComponent({
  name: "ElectronicSignatureTableCell",
  computed: {
    icon(): string {
      return `${environmentVariables.BASE_URL}assets/images/ead/ead-element.svg`;
    },
  },
  props: {
    digitalSignatures: {
      type: Array,
      default() {
        return [];
      },
    },
  },
});
